import logo from './images/default-img.png';
import './App.css';
import {React, useRef} from 'react';
import { useState, useEffect } from 'react';
import {Link, BrowserRouter, Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import AdminLogin from './AdminLogin.js';
import AdminPage from './AdminPage.js';
import { getAuth, signInAnonymously } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, getDocs, collection, query, where, orderBy } from "firebase/firestore";
import { Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ManageActors from './ManageActors.js';

const App = () => {
  return (
    <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/:id" element={<WatchPage />}/>
            <Route path="/admin" element={<AdminLogin />}/>
            <Route path="/myfilms" element={<AdminPage />}/>
          </Routes>
        </BrowserRouter>
    </>
  )
};

const HomePage = () => {

  const [films, setFilms] = useState();
  const [semesters, setSemesters] = useState();
  const [search, setSearch] = useState();
  const [showRestrictedFilms, setShowRestricted] = useState(false);
  const [showUnavailableFilms, setShowUnavailable] = useState(false);
  const [showIndependent, setShowIndependent] = useState(false);
  const [showBonusFilms, setShowBonus] = useState(false);
  const [allCast, setAllCast] = useState([]);
  const [tag, setTag] = useSearchParams();

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyByx_HtrqJm-cI8F1c6kYI9arYHFiLdDbA",
      authDomain: "buzz-studios-7f814.firebaseapp.com",
      databaseURL: "https://buzz-studios-7f814-default-rtdb.firebaseio.com",
      projectId: "buzz-studios-7f814",
      storageBucket: "buzz-studios-7f814.appspot.com",
      messagingSenderId: "103909984010",
      appId: "1:103909984010:web:6b15701bb800c731cd1cf3",
      measurementId: "G-GY05FJ1M7L"
    };
    
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const fetch = async () => {
      var db = getFirestore(app);
      var docRef = collection(db, "films");
      var q = query(docRef, orderBy("order"));
      var docSnap = await getDocs(q);

      var films = [];
      docSnap.forEach((doc) => {
        var data = doc.data();
        data['id'] = doc.id;

        if (data['semester'] != "Do Not Show")
          films.push(data);
      });
      const semesters = Array.from(new Set(films.map(film => film.semester)));
      
      setFilms(films);
      setSemesters(sortSemesters(semesters));
      setSearch("");
    }
    fetch();
  }, []);


  const getFilmsInSemesterRow = (semesterFilms) => {
    const rows = [];

    for (let i = 0; i < semesterFilms.length; i += 25) {
      const row = semesterFilms.slice(i, i + 25);
      rows.push(row);
    }

    return rows;
  };

  function sortSemesters(semesters) {
    const seasonOrder = {
      Spring: 0,
      Summer: 1,
      Fall: 2,
      'Date Unknown': 3,
      'Extra Material': 4
    };
  
    semesters.sort((a, b) => {
      const [seasonA, yearA] = a.split(' ');
      const [seasonB, yearB] = b.split(' ');
  
      // Check if either film has "Date Unknown"
      const hasDateUnknownA = seasonA === 'Date' && yearA === 'Unknown';
      const hasDateUnknownB = seasonB === 'Date' && yearB === 'Unknown';

      const hasExtraMaterialA = seasonA === 'Extra' && yearA === 'Material';
      const hasExtraMaterialB = seasonB === 'Extra' && yearB === 'Material';
  
      // Handle "Date Unknown" films
      if (hasDateUnknownA && hasDateUnknownB) {
        return 0; // Keep the order as is
      } else if (hasDateUnknownA || hasExtraMaterialA) {
        return 1; // Move "Date Unknown" film to the end
      } else if (hasDateUnknownB || hasExtraMaterialB) {
        return -1; // Move "Date Unknown" film to the end
      }
  
      // Compare years first
      if (yearA !== yearB) {
        return parseInt(yearB) - parseInt(yearA);
      }
  
      // If years are the same, compare seasons
      return seasonOrder[seasonB] - seasonOrder[seasonA];
    });
  
    return semesters;
  }

  function formatNames(names) {
    if (names.length === 0) return '';
    if (names.length === 1) return names[0];
    if (names.length === 2) return `${names[0]} and ${names[1]}`;
  
    // For 3 or more names
    const lastName = names.pop();
    return `${names.join(', ')}, and ${lastName}`;
  }

  function filterBySearch(films, data) {


    var newFilms = [[]];

    // if (search == "") {
    //   return films;
    // } else {
    //   for (let e = 0; e < films[0].length; e++) {
    //     if (films[0][e].title.toLowerCase().trim().includes(search.toLowerCase().trim())) {
    //       newFilms[0].push(films[0][e]);
    //     }
    //   }
    // }

    var hidden = 0;
    var noSearch = 0;

    for (let e = 0; e < films[0].length; e++) {
      if (films[0][e].independent === undefined) { films[0][e].independent = false; }
      if (films[0][e].bonus === undefined) { films[0][e].bonus = false; }

      var independentNotMatch = films[0][e].independent != showIndependent;
      var bonusNotMatch = films[0][e].bonus != showBonusFilms;
      var tagNotMatch = false;

      var tagInUse = tag.get("tag");
      var actorInUse = tag.get("actor");

      if (tagInUse !== null)
      {
        if (films[0][e].tags == undefined || !(films[0][e].tags.includes(tagInUse)))
        {
          tagNotMatch = true;
        }
      }

      if (actorInUse !== null)
      {
        var found = false;
        if (films[0][e]["cast-new"] != undefined)
        {
          for (var actor in films[0][e]["cast-new"])
          {
            if (films[0][e]["cast-new"][actor].actor == actorInUse)
            {
              found = true;
              break;
            }
          }
        }

        if (!found) { tagNotMatch = true; }
      }

      if (independentNotMatch || bonusNotMatch || tagNotMatch) {
        noSearch++;
        continue;
      }

      if (search !== "" && search !== undefined) {
        var searchMatch = search.toLowerCase().trim();
        var titleMatch = films[0][e].title.toLowerCase().trim();
        //var directorMatch = films[0][e].title.toLowerCase().trim();

        // var actors = [];
        // for (var f in allCast)
        // {
        //   for (var actor in allCast[f]) {
        //     var actorMatch = actor.name.trim().toLowerCase();
        //     if (actorMatch.contains(searchMatch)) {
        //       actors.push(actor.name);
        //     }
        //   }
        // }

        var titleContains = titleMatch.includes(searchMatch);
        //var directorContains = directorMatch.includes(searchMatch);
        //var actorContains = actors.length > 0;

        // var message = "";
        // if (directorContains) {
        //   message += "Directed by " + films[0][e].director + "\n";
        // }
        // if (actorContains) {
        //   message += "Features " + formatNames(actors) + "\n";
        // }

        if (!titleContains)// && !directorContains)//} && !actorContains)
        {
          noSearch++;
          continue;
        }
      }

      if (!showRestrictedFilms && films[0][e].access === "restricted") {
        hidden++;
        continue;
      }
      if (!showUnavailableFilms && (films[0][e].access === "unavailable" || films[0][e].access === "preprod" || films[0][e].access === "postprod" || films[0][e].access === "prod")) {
        hidden++;
        continue;
      }
      newFilms[0].push(films[0][e]);
    }

    if (data == 0) {
      return newFilms;
    } else if (data == 1) {
      return hidden;
    } else if (data == 2) {
      return noSearch != films[0].length;
    }
  }

  return (
    <>
    <head>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8659122665799697"
     crossorigin="anonymous"></script>
     <meta name="google-adsense-account" content="ca-pub-8659122665799697"></meta>
    </head>
    <div className="homepage">
      <BuzzHeader/>

      <div className="intro-box">
        {/* <p className="intro-header"><strong>We are currently migrating the archive to a new content delivery system. Some films may be unavailable until this is completed.</strong></p>
        <br></br> */}
        <p className="intro-header"><strong>Welcome to the Buzz Studios Film Archive.</strong></p>
        <p className="intro-body">
          Buzz Studios was chartered in 2001 by a group of students 
          hoping to create a community of filmmakers, storytellers, 
          and artists that would enrich the Georgia Tech campus with 
          its work and its welcoming environment. 22 years in, this 
          mission statement guides us all as we continue to tell the 
          stories of our members. In order to honor our past and look 
          forward to our future, we have created the Buzz Studios Film 
          Archive, intended to document our club's entire body of work 
          as thoroughly as humanly possible. It is our hope that this 
          website will be maintained well into the future and continue to 
          document the work of Georgia Tech's longest-running filmmaking 
          organization.<br></br><br></br>- Ethan Ollins, President 2023-2025<br></br><br></br>
          This archival mission is an ever-ongoing work in progress. Alumni 
          are strongly encouraged to reach out to help us realize this vision. 
          If you would like to help contribute to this archive, please reach 
          out at:<br></br><br></br><strong><a style={{color: "white"}} href="mailto:info@buzzstudios.org">info@buzzstudios.org</a></strong>
          <br></br><br></br><br></br><i>Established April 22, 2023.</i>
          </p>
        </div>
      <main>
        <div className="content">
          <div class="search-bar" style={{marginBottom: 0}}>
              <input onChange={(e) => setSearch(e.target.value)} type="text" class="input-field" placeholder="Search..."/>
          </div>
          <div style={{color: "white", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline"}}>
            <input onClick={() => setShowRestricted(!showRestrictedFilms)} checked={showRestrictedFilms} type="checkbox" style={{transform: "scale(1.5)"}}/><p onClick={() => setShowRestricted(!showRestrictedFilms)} style={{marginLeft: 16, fontSize: 18, paddingBottom: 3, cursor: "pointer", userSelect: "none"}}>Show Access-Restricted Films</p>
          </div>
          <div style={{color: "white", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: -30, alignItems: "baseline"}}>
            <input onClick={() => setShowUnavailable(!showUnavailableFilms)} checked={showUnavailableFilms} type="checkbox" style={{transform: "scale(1.5)"}}/><p onClick={() => setShowUnavailable(!showUnavailableFilms)} style={{marginLeft: 16, fontSize: 18, paddingBottom: 3, cursor: "pointer", userSelect: "none"}}>Show Unreleased Films</p>
          </div>
          {/* <div style={{color: "white", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: -30, alignItems: "baseline"}}>
            <input onClick={() => setShowBonus(!showBonusFilms)} checked={showBonusFilms} type="checkbox" style={{transform: "scale(1.5)"}}/><p onClick={() => setShowBonus(!showBonusFilms)} style={{marginLeft: 16, fontSize: 18, paddingBottom: 3, cursor: "pointer", userSelect: "none"}}>Show Bonus Material</p>
          </div>
          <div style={{color: "white", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: -30, alignItems: "baseline"}}>
            <input onClick={() => setShowIndependent(!showIndependent)} checked={showIndependent} type="checkbox" style={{transform: "scale(1.5)"}}/><p onClick={() => setShowIndependent(!showIndependent)} style={{marginLeft: 16, fontSize: 18, paddingBottom: 3, cursor: "pointer", userSelect: "none"}}>Show Unguided Films</p>
          </div> */}

          <FormControl >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="regular"
              name="radio-buttons-group"
              class="radio-row"
              row
            >
              <FormControlLabel onClick={() => {setShowIndependent(false); setShowBonus(false);}} value="regular" style={{color: "white"}} control={<Radio style={{color: "white"}}/>} label="Regular Films" />
              <FormControlLabel onClick={() => {setShowIndependent(true); setShowBonus(false);}} value="unguided" style={{color: "white"}} control={<Radio style={{color: "white"}}/>} label="Self-Guided Films" />
              <FormControlLabel onClick={() => {setShowIndependent(false); setShowBonus(true);}} value="bonus" style={{color: "white"}} control={<Radio style={{color: "white"}}/>} label="Bonus Material" />
            </RadioGroup>
          </FormControl>
          <br></br>

          {tag.get("tag") !== null && <><br></br><p style={{color: "lightgray", fontSize: 22}}>Filtering by tag: <br></br><strong>{tag.get("tag")}</strong></p><Button onClick={() => {window.location.href = "/";}} variant="outlined" style={{marginTop: -10, color: "gray", borderColor: "gray"}}>Clear</Button></>}

          {tag.get("actor") != null && <><br></br><p className="intro-body">Displaying films with <strong>{tag.get("name")}</strong> as cast member</p><Button onClick={() => {window.location.href = "/";}} variant="outlined" style={{marginTop: -6, color: "gray", borderColor: "gray", maxHeight: 35}}>Clear</Button><br></br><br></br></>}

          {/* <Button variant="contained" style={{backgroundColor: "black"}} onClick={() => {setShowIndependent(!showIndependent)}}>{showIndependent ? "See Guided Films" : "See Unguided Films"}</Button> */}
          {showIndependent && <p class="intro-body"> This section contains films sponsored by<br></br> Buzz Studios and produced outside the<br></br>official production schedule.</p>}
          {showBonusFilms && <p class="intro-body"> This section contains blooper reels, trailers,<br></br>practice films, and other material not<br></br>applicable to the other categories.</p>}

          {semesters !== undefined && semesters.map((semester, i) => {
            const semesterFilms = films.filter(film => film.semester === semester);
            const filmsInRow = filterBySearch(getFilmsInSemesterRow(semesterFilms), 0);
            const filmsNotShown = filterBySearch(getFilmsInSemesterRow(semesterFilms), 1);
            const showHeading = filterBySearch(getFilmsInSemesterRow(semesterFilms), 2);

            //if (filmsInRow[0].length > 0) {
              return (
                showHeading && <div id={semester} className="semester" key={semester}>
                  <h2>{semester}</h2>
                  {filmsNotShown == 1 && <h4 style={{marginTop: -20, color: "gray"}}><i>1 hidden film not displayed below.</i></h4>}
                  {filmsNotShown > 1 && <h4 style={{marginTop: -20, color: "gray"}}><i>{filmsNotShown + " hidden films not displayed below."}</i></h4>}
                  <div className="films">
                    {filmsInRow.map((filmRow, index) => (
                      <div className="film-row" key={index}>
                        {filmRow.map(film => (
                          <Film film={film}/>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              );
            //}
          })}
        </div>
      </main>
    </div>
    </>
  );
};

const Tag = (props) => {
  return (
    <>
      <p class="tag-body">{props.tag}</p>
    </>
  )
}

const WatchPage = () => {
  const { id } = useParams();
  const [filmData, setFilmData] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [url, setURL] = useState(false);
  const [urlLoaded, setLoaded] = useState(false);
  const [otherFilms, setOtherFilms] = useState({});
  const [numDirectors, setNumDirectors] = useState(1);
  const [scriptURL, setScriptURL] = useState("");
  const [captionsURL, setCaptionsURL] = useState("");
  const [cast, setCast] = useState();
  const [usedPassword, setUsedPassword] = useState();
  const [showCast, setShowCast] = useState();
  const [otherCast, setOtherCast] = useState({});
  const [bios, setBios] = useState([]);
  const [allFilms, setAllFilms] = useState();
  const [notFound, setNotFound] = useState(false);
  const [associated, setAssociated] = useState({});
  const [actors, setActors] = useState([]);

  const videoRef = useRef();

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyByx_HtrqJm-cI8F1c6kYI9arYHFiLdDbA",
      authDomain: "buzz-studios-7f814.firebaseapp.com",
      databaseURL: "https://buzz-studios-7f814-default-rtdb.firebaseio.com",
      projectId: "buzz-studios-7f814",
      storageBucket: "buzz-studios-7f814.appspot.com",
      messagingSenderId: "103909984010",
      appId: "1:103909984010:web:6b15701bb800c731cd1cf3",
      measurementId: "G-GY05FJ1M7L"
    };
    
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();

    const fetchFilms = async () => {
      setFilmData({});
      setAuthenticated(false);
      setURL(false);
      setLoaded(false);
      setOtherFilms({});
      setOtherCast({});
      setAssociated({});
      setNumDirectors(1);
      setScriptURL("");
      setCaptionsURL("");
      setCast();
      setUsedPassword("");
      setShowCast(false);
      setActors([]);

      var db = getFirestore(app);
      var docRef = doc(db, "films", id);
      var docSnap = await getDoc(docRef);
      var data = docSnap.data();
      setNotFound(data === undefined);
      if (data === undefined) {
        docRef = doc(db, "films", "404");
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      data['id'] = doc.id;

      docRef = collection(db, "films");
      var q = query(docRef, orderBy("order"));
      docSnap = await getDocs(q);
      setAllFilms(docSnap);

      var filmsByDirector = [];
      var assoc = [];

      var directors = data['director'];
      var names = splitNames(directors);
      setNumDirectors(names.length);

      var bios = [];

      docSnap.forEach((doc) => {
        var film = doc.data();

        for (var i = 0; i < names.length; i++) {
          if (film.director.includes(names[i]) && film.title != data.title && !film.bonus) {
            film['id'] = doc.id;
            filmsByDirector.push(film);
          }
        }

        if (data.associated !== undefined && data.associated.includes(doc.id))
        {
          film['id'] = doc.id;
          assoc.push(film);
        }
      }
    );

      setFilmData(data);
      setOtherFilms(filmsByDirector);
      setAssociated(assoc);

      docRef = collection(db, "directors");
      docSnap = await getDocs(docRef);
      docSnap.forEach((doc) => {
        var director = doc.data();

        if (names.includes(director.name)) {
          bios.push(director);
        }
      });

      var actorList = [];
      docRef = collection(db, "actors");
      docSnap = await getDocs(docRef);
      docSnap.forEach((doc) => {
        var actor = doc.data();
        actor.id = doc.id;
        actorList.push(actor);
      });
      setActors(actorList);

      setBios(bios);

      if (data.access === "released") {
        signInAnonymously(auth)
          .then(() => {
            fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "title": data.title,
              "film": data.filmfile,
              "uid": auth.currentUser.uid
            })
          })
          .then(response => response.json())
          .then(d => {
            setURL(d.url);
            setAuthenticated(true);
          })
        })
        .catch((error) => {
          alert("Could not authenticate.")
        });
      }
    }
    fetchFilms();
  }, [id]);

  var password = "";

  const authenticate = () => {
    const auth = getAuth();

    fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "title": filmData.title,
        "film": filmData.filmfile,
        "code": password
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.url != "403") {
        signInAnonymously(auth)
        .then(() => {
            setURL(data.url);
            setAuthenticated(true);
            setUsedPassword(password);
        })
        .catch((error) => {
          alert("Could not authenticate.");
        });
      } else {
        alert("Incorrect access code.");
      }
    })
  }

  useEffect(() => {
    if (authenticated && !urlLoaded) {
        setLoaded(true);
        videoRef.current?.load();
        
        if (filmData.script !== undefined && filmData.script !== "") {
          requestScript();
        }
        if (filmData.captions !== undefined && filmData.captions !== "") {
          requestCaptions();
        }
    }
  }, [authenticated]);

  // useEffect(() => {
  //   if (allFilms !== undefined && cast !== undefined)
  //   {
  //     var filmsWithCast = [];

  //     allFilms.forEach((doc) => {
  //       var film = doc.data();

  //       if (doc.id !== id) {
  //         for (var i = 0; i < cast.length; i++) {
  //           var name = cast[i][0];
            
  //           if (film.cast !== undefined) {

  //             for (var j = 0; j < film.cast.length; j++) {
  //               if (film.cast[j][0] == name) {
  //                 if (!(name in filmsWithCast)) {
  //                   filmsWithCast[name] = [];
  //                 }
  //                 var f = doc.data();
  //                 f.id = doc.id;

  //                 if (!(filmsWithCast[name].some(e => e.id === f.id))) {
  //                   filmsWithCast[name].push(f);
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     });
  //   }
        
  //   setOtherCast(filmsWithCast);
  // }, [cast]);

  const requestScript = () => {
    const auth = getAuth();
    fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "title": filmData.title,
        "film": filmData.script,
        "uid": auth.currentUser.uid,
        "code": usedPassword
      })
    })
    .then(response => response.json())
    .then(d => {
      setScriptURL(d.url);
    });
  }

  const requestCaptions = () => {
    const auth = getAuth();
    fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "title": filmData.title,
        "film": filmData.captions,
        "uid": auth.currentUser.uid,
        "code": usedPassword
      })
    })
    .then(response => response.json())
    .then(d => {
      setCaptionsURL(d.url);
    });
  }

  const getActorName = (id) => {
    for (var actor in actors)
    {
      if (actors[actor].id == id) { return actors[actor].name; }
    }
  }


  const styles = {
    tableContainer: {
      maxWidth: '600px',
      margin: '20px auto',
      borderCollapse: 'collapse',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    },
    table: {
      width: '100%',
      border: '1px solid #ddd',
    },
    th: {
      backgroundColor: '#f4f4f4',
      padding: '12px',
      textAlign: 'left',
      fontWeight: 'bold',
      borderBottom: '2px solid #ddd',
    },
    td: {
      padding: '10px',
      borderBottom: '1px solid #ddd',
    },
    trHover: {
      cursor: 'pointer',
      transition: 'background-color 0.2s',
    },
    trHoverActive: {
      backgroundColor: '#f9f9f9',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
      fontSize: '24px',
    },
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark", // Enables dark mode
      background: {
        default: "#000", // Black background
        paper: "#111", // Slightly lighter for table rows/paper
      },
      text: {
        primary: "#fff", // White text
        secondary: "#ccc", // Light gray text for secondary
      },
    },
  });

  return (
    <div>
      <BuzzHeader/>
      <br></br><br></br>
      <div className="watch-page">
        <div className={filmData.access == "released" || authenticated ? ("video-container") : ("msg-container")}>
          {filmData.access == "released" || authenticated? (
            <video ref={videoRef} crossOrigin='anonymous' playsInline controls controlsList="nodownload" class="player">
              <source src={url}/>
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src={captionsURL}
                 
              />
            </video>
          ) : filmData.access == "unavailable" || filmData.access == "preprod" || filmData.access == "prod" || filmData.access == "postprod" ? (
            <div className="coming-soon">
                <p class="cs"><b>Coming Soon</b></p>
                <p class="css">Please check back later.</p>
            </div>
          ) : filmData.access == "restricted" ? (
            <div className="coming-soon">
                <p class="cs"><b>Restricted</b></p>
                <p class="css">This film has not yet been made available to the general public. Please enter the access code.</p>
                <br></br><input type="password" class="accesscode" onChange={(event) => password = event.target.value}></input>
                <button onClick={authenticate} class="submitaccesscode">Submit</button>
            </div>
          ) : !notFound ? <div className="coming-soon">
              <p class="cs"><b>Loading...</b></p>
            </div>
            : <div className="coming-soon">
            {/* <p class="cs"><b>"For the 404, for the A!"</b></p>
            <p class="css">This film could not be found.</p> */}
            <p class="cs"><b>Loading...</b></p>
          </div>}
        </div>
        {filmData.access !== undefined && <div className={filmData.independent ? "info-box-indep" : filmData.bonus ? "info-box-bonus" : "info-box"}>
          
          {!showCast && <><strong><p class="title">{filmData.title}</p></strong>
          <p class="subheading">Directed by {filmData.director}</p>
          <p class="subheading">Starring {filmData.stars}</p><br></br>
          <p class="synopsis">{filmData.synopsis}</p>

          {filmData.tags !== undefined &&
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px"}}>
          {filmData.tags.map((tag, i) => {
            return (
                <a style={{color: "white", textDecoration: "none"}} ><Tag tag={tag}/></a> //href={"/?tag=" + tag}
            )
          })}
          </div>}

          {scriptURL !== "" && <Link style={{textDecoration: 'none'}} id="scriptLink" target="_blank" download to={scriptURL}><Button style={{margin: "0 auto", width: 200, backgroundColor: "black", display: "block", marginTop: "25px"}} variant="contained" id="scriptDownload">Download Script</Button></Link>}
          </>}

          {showCast && <><ThemeProvider theme={darkTheme}>
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table" mode="dark">
            <TableHead>
              <TableRow>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Role</strong></TableCell>
                <TableCell align="right"><strong>See More</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filmData["cast-new"].map((row) => (
                <TableRow
                  key={row.actor}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell size="small" component="th" scope="row">
                    {getActorName(row.actor)}
                  </TableCell>
                  <TableCell size="small" >{row.role}</TableCell>
                  <TableCell size="small" variant="contained" align="right"><Button onClick={() => {window.location.href = "/?actor=" + row.actor + "&name=" + getActorName(row.actor)}}>View</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </ThemeProvider></>}

        {filmData["cast-new"] !== undefined && <Button onClick={() => {setShowCast(!showCast)}} style={{margin: "0 auto", width: 200, backgroundColor: "black", display: "block", marginTop: scriptURL !== "" ? "10px" : "25px"}} variant="contained" id="scriptDownload">{showCast ? "Hide Cast" : "Show Cast"}</Button>}
        </div>}
      </div>
      <br></br>

      {(bios.length > 0) && <div style={{marginTop: "0px"}} className="watch-page">
          <main>
            {bios.map((bio => {
              return (
                <>
                <h2 style={{marginBottom: -10}}>About {bio.name}</h2>
                <div className="bio" >
                  <img src={"https://firebasestorage.googleapis.com/v0/b/buzz-studios-7f814.appspot.com/o/" + bio.headshot + "?alt=media"}></img>
                  <p>{bio.bio}{bio.bio}{bio.bio}</p>
                </div>
                </>
              )
            }))}
          </main>
        </div>}

      {(otherFilms.length > 0 && filmData.director != "N/A" && !filmData.bonus) && <div style={{marginTop: "0px"}} className="watch-page">
          <main>
          <div className="semester"  key={-1}>
            <h2>{numDirectors == 1 ? "Other Films By This Director" : "Other Films By These Directors"}</h2>
            <div className="films" >
              <div className="film-row" style={{display: "flex", alignItems: "center", flexDirection: "column", flexFlow: "wrap", justifyContent: "center"}} key={0}>
                {otherFilms.map(film => (
                  <Film film={film}/>
                ))}
              </div>
            </div>
          </div>
          </main>
        </div>}

      {(filmData.associated !== undefined && filmData.associated.length > 0) && <div style={{marginTop: (otherFilms.length > 0 && filmData.director != "N/A" && !filmData.bonus) ? "-40px" : "0px"}} className="watch-page">
        <main>
        <div className="semester"  key={-1}>
          <h2>Bonus Material</h2>
          <div className="films" >
            <div className="film-row" style={{display: "flex", alignItems: "center", flexDirection: "column", flexFlow: "wrap", justifyContent: "center"}} key={0}>
              {associated.map(film => (
                <Film film={film}/>
              ))}
            </div>
          </div>
        </div>
        </main>
      </div>}
        
       {/* {(showCast && otherCast != {}) && <div style={{marginTop: "0px"}} className="watch-page">
        <main>
        {Object.keys(otherCast).map((actor, i) => {
          return (
            <>
            <div className="semester"  key={i}>
              <h2>Other Films Featuring {actor}</h2>
              
              <div className="films" >
                <div className="film-row" style={{display: "flex", alignItems: "center", flexDirection: "column", flexFlow: "wrap", justifyContent: "center"}} key={0}>
                  {otherCast[actor].map(film => (
                    <Film film={film}/>
                  ))}
                </div>
              </div>
            </div>
            </>
          )
        })}
        </main>
      </div>} */}
    </div>
  );
};

const BuzzHeader = () => {
  return (
    <div className="header">
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Link to="/">
            <img src={logo} alt="Buzz Studios Filmmaking Club" />
          </Link>
          </div>
      </div>
  )
};

const MenuButton = (props) => {
  return (
    <div style={{backgroundColor: "#222222", width: props.Width, height: "50px", justifyContent: "center", alignItems: "center", display: "flex", borderRadius: "5px"}}>
      <strong style={{color: "white", fontSize: "20px", marginBottom: "5px"}}>{props.Text}</strong>
    </div>
  )
}

const Film = (props) => {
  return (
    <div className="film" style={{margin: props.condensed ? "-20px" : "0px"}} key={props.film.id}>
      <Link to={`/${props.film.id}`}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/buzz-studios-7f814.appspot.com/o/` + props.film.thumbnail + `?alt=media`} />
        <div className="overlay">
          {
            (props.film.title.includes(":") && <h3>{props.film.title.split(":")[0] + ":"}<br></br>{props.film.title.split(":")[1]}</h3>) || !props.film.title.includes(":") && <h3>{props.film.title}</h3>
          }
          {
            props.film.independent && <p className="independent-icon"><strong>SELF-GUIDED</strong></p>
          }
          {
            props.film.bonus && <p className="bonus-icon"><strong>BONUS</strong></p>
          }
          {
            props.film.access == "preprod" && <p className="status-icon" style={{marginTop: props.film.independent || props.film.bonus ? -10 : -18 }}><strong>PRE-PRODUCTION</strong></p>
          }
          {
            props.film.access == "prod" && <p className="status-icon" style={{marginTop: props.film.independent || props.film.bonus ? -10 : -18 }}><strong>PRODUCTION</strong></p>
          }
          {
            props.film.access == "postprod" && <p className="status-icon" style={{marginTop: props.film.independent || props.film.bonus ? -10 : -18 }}><strong>POST-PRODUCTION</strong></p>
          }
          {
            props.film.access == "restricted" && <p className="restricted-icon" style={{marginTop: props.film.independent || props.film.bonus ? -10 : -18 }}><strong>RESTRICTED</strong></p>
          }
          {
            props.film.captions && props.film.captions !== "" && <p className="cc-icon" style={{marginTop: props.film.independent || props.film.bonus ? -10 : -18 }}><strong>CC</strong></p>
          }
          {
            props.film.message && <p className="subtitle">{props.film.message}</p>
          }
        </div>
      </Link>
    </div>
  )
}

function splitNames(nameList) {
  let names = nameList.split(',');

  let finalNames = [];

  names.forEach(name => {
      if (name.includes(' and ')) {
          finalNames.push(...name.split(' and ').map(n => n.trim()));
      } else {
          finalNames.push(name.trim());
      }
  });

  return finalNames;
}

export default App;